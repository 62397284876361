import { ReactNode } from 'react';

export enum CONTAINER_VARIANT {
  ROW = 'row',
  COLUMN = 'column',
}

export enum CONTAINER_ROW_LAYOUT {
  ONE_ROW = '1-row',
  TWO_ROWS = '2-row',
  THREE_ROWS = '3-row',
  FOUR_ROWS = '4-row',
}

export enum CONTAINER_ALIGN_CONTENT {
  FLEX_START = 'flex-start',
  CENTER = 'center',
  FLEX_END = 'flex-end',
}

export type ContainerProperties = {
  variant: CONTAINER_VARIANT;
  layout: CONTAINER_ROW_LAYOUT;
  alignContent: CONTAINER_ALIGN_CONTENT;
  children: ReactNode;
  className?: string;
};
