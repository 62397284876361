import { Dispatch, SetStateAction, useState } from 'react';
import useSWR from 'swr';
import { ClicksData } from '../../providers/collinson/collinson.types';
import fetcher from '../../utils/fetcher';

const VISIT_HISTORY_INITIAL_LIMIT = 12;
const VISIT_INITIAL_INITIAL_PAGE = 1;

const useVisitHistoryLoadMore = ({
  limitDefault = VISIT_HISTORY_INITIAL_LIMIT,
  pageDefault = VISIT_INITIAL_INITIAL_PAGE,
  dateIsMandatory = false,
  maxDays = 0,
} = {}): {
  data: ClicksData[];
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  date: string | undefined;
  setDate: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
} => {
  const [limit, setLimit] = useState<number>(limitDefault);
  const [page, setPage] = useState<number>(pageDefault);
  const [date, setDate] = useState<string | undefined>();
  const dateQueryField = dateIsMandatory && date ? `&date=${date}` : '';
  const maxDaysQueryField =
    maxDays > 0 && !dateQueryField ? `&max-days=${maxDays}` : '';

  const route = `/api/last-visited?page=${page}&limit=${limit}${dateQueryField}${maxDaysQueryField}`;

  const response = useSWR(route, fetcher);

  const { isLoading, data } = response;

  return { data, isLoading, limit, setLimit, page, setPage, date, setDate };
};

export default useVisitHistoryLoadMore;
